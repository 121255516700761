@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./fonts.css");
@import url("./custom.css");
@import url("./modules/article-content-block.css");
@import url("./modules/product-card.css");
@import url("./modules/product-zoom-carousel.css");
@import url("./modules/promobar.css");
@import url("./modules/warranties-repair.css");
@import url("./modules/product-gallery.css");
@import url("./modules/hero-module.css");
@import url("./modules/pro-text-content.css");
@import url("./modules/seo-module.css");
@import url("./modules/yotpo.css");
@import url("./modules/inline-banner.css");
@import url("./pages/pro-program.css");
@import url("./modules/article.css");
@import url("./modules/article-header.css");
@import url("./modules/rich-text-content.css");
@import url("./modules/profile-slider.css");
@import url("./modules/module-markdown.css");
@import url("./modules/size-chart.css");
body {
  font-family: "Proxima Nova";
}

#__next {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.common-input {
  border: 1px solid #e7e5e4;
  min-height: 50px;
  outline: none;
  padding: 15px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  margin-bottom: 14px;
  position: relative;
  color: #000;
}
@media screen and (min-width: 768px) {
  .common-input {
    margin-bottom: 24px;
  }
}

.image-one-up img{
  height: 413px;
  object-fit: cover;
}



.error-input {
  border: 1px solid #C10000 !important;
}

.common-table-body:nth-child(even) {
  background: #f7f7f7;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-padding-end: 20px;
  -moz-padding-end: 20px;
  -webkit-padding-start: 2px;
  -moz-padding-start: 2px;
  background-color: #ffffff; /* Fallback color if gradients are not supported */
  background-image: url('../public/images/selectIcon.svg');
  background-position: calc(100% - 10px);
  background-repeat: no-repeat;
  border: 1px solid #E7E5E4;
  border-radius: 2px;
  /* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1); */
  /* color: #555; */
  font-size: inherit;
  margin: 0;
  overflow: hidden;
  padding-top: 2px;
  padding-bottom: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;

}
/*For IE*/
select::-ms-expand {
  display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.error-box {
  min-height: 25px;
  font-size: 14px;
}

.error-text {
  color: #C10000;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  font-weight: 600;
  font-family: 'Proxima Nova Regular'
}

.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: #f7f7f7 !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
  color: #000 !important;
}

.is-sticky .fill-white {
  /* Mobile icon */
  fill: white;
}

.UnfixedHeader .fill-white {
  /* Mobile icon */
  fill: white;
}

.mobile-menu::-webkit-scrollbar {
  width: 0;
}

.shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.web-UnfixedHeader .fill-white {
  fill: white;
}

body::-webkit-scrollbar {
  display: none;
}

@screen md {
  .footer-section .ReactCollapse--collapse {
    height: auto !important;
  }

}

@media (max-width: 768px) {

  .prose_mobile {
    width: 100%;
  }
  .prose_mobile h3 {
    text-align: left !important;
    font-size: 20px;
  }

  .footer-section [data-item="2"] {
    order: 1;
  }

  .footer-section [data-item="0"] {
    order: 3 ;
  }

  .footer-section [data-item="1"] {
    order: 2 ;
  }

  .footer-section [data-icon="1"]{
    order: 3 ;
  }

  .footer-section [data-icon="1"] svg{
    margin-right: -40px;
  }
}

.splide__arrow {
  background-color: #ffffff !important;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.12);
  border-radius: 48px !important;
  width: 48px !important;
  height: 48px !important;
  opacity: 1 !important;
}

.container.prose p, .container.prose ul li, .prose-details-card p {
  font-size: 16px;
  line-height: 26px;
}

.container.prose p, .prose.article-content-block p  {
  line-height: 26px;
  font-size: 16px;
}

.prose-details-card li::marker {
  color: black;
}

.promo-title-sub p {
  font-size: 16px;
  font-weight: 400;
}

.my-slider-progress-bar {
  height: 2px;
  transition: width 400ms ease;
  width: 0;
}
.my-slider-progress-bar.progress-galery-bar {
  height: 6px;
}

@media (min-width: 768px) {

  .slider-progress-galery {
    display: block !important;
    background-color: rgb(200,200,200);
    margin-bottom: 15px;
  }
}

  /* .splide__arrow {
    background-color: transparent !important;
  } */

 .splide__arrow svg path {
    fill: #000;
 }

 .splide__arrow--next  svg{
    width: 24px !important;
    height: 24px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
 }

.splide__arrow--prev {
  display: none
}

.splide__arrow--prev  svg{
  width: 24px !important;
  height: 24px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}


.unscroll-body {
  overflow: hidden !important;
  height: 100%;
  position: fixed;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  /* Other browsers */
  overscroll-behavior: none;
}



input::placeholder {
  color: #847770;
}

.w-inherit {
  width: inherit;
}

/* CheckBox */

.checkbox {
  position: relative;
  display: block;
}

.checkbox input[type="checkbox"] {
  width: auto;
  opacity: 0.00000001;
  position: absolute;
  left: 0;
  margin-left: -20px;
}
.checkbox label {
  position: relative;
}
.checkbox label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 22px;
  height: 22px;
  transition: transform 0.28s ease;
  border-radius: 3px;
  border: 2px solid #cac8c8;
}
.checkbox label:after {
  content: "";
  display: block;
  width: 10px;
  height: 5px;
  border-bottom: 2px solid #dd5f13;
  border-left: 2px solid #dd5f13;
  -webkit-transform: rotate(-45deg) scale(0);
  transform: rotate(-45deg) scale(0);
  transition: transform ease 0.25s;
  will-change: transform;
  position: absolute;
  top: 12px;
  left: 10px;
}
.checkbox input[type="checkbox"]:checked ~ label::before {
  color: #dd5f13;
  border: 2px solid #dd5f13;
}

.checkbox input[type="checkbox"]:checked ~ label::after {
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1);
}

.checkbox label {
  min-height: 34px;
  display: block;
  padding-left: 30px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
  vertical-align: sub;
}
.checkbox label span {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.checkbox input[type="checkbox"]:focus + label::before {
  outline: 0;
}


/* CheckBox END*/

.lock-scroll {
  overflow: hidden;
}

.pagination-disabled {
  /* opacity: .5; */
  pointer-events: none;
}

.color-image-filter-wrap {
  margin-bottom: 8px;
  margin-right: 8px;
  border-radius: 50%;
}
.color-image-filter-wrap:hover {
  border: 2px solid black !important;
}
.color-image-filter-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: 2px;
}
.color-image-filter-wrap img {
  border-radius: 50%;
}
.product-item-label {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.01em;
  color: #000000;
  height: 22px;
  right: 12px;
  top: 12px;
  background: #81746C;
  border-radius: 2px;
  padding: 4px 6px;
}


/* INPUT LOADER */
.icon-container {
  position: absolute;
  right: 20px;
  top: calc(50% - 10px);
}

.loader {
  position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;
  animation: around 5.4s infinite;
}

.loader-product {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #878787;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: around 2.4s infinite;
}


@keyframes around {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

.loader::after,
.loader::before {
  content: "";
  background: white;
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-color: #333 #333 transparent transparent;
  border-style: solid;
  border-radius: 20px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  animation: around 0.7s ease-in-out infinite;
}

.loader::after {
  animation: around 0.7s ease-in-out 0.1s infinite;
  background: transparent;
}

.feature-specs .feature_item {
  position: relative;
  margin-bottom: 12px;
  padding-left: 20px;
  font-size: 12px;
  line-height: 22px;
}

.feature-specs .feature_item:last-child {
  margin-bottom: 0px;
}


.remove-marker::marker {
    color: transparent;
}

.feature-specs .feature_item::before {
  content: '';
  width: 7px;
  height: 7px;
  position: absolute;
  top: 8px;
  left: 0;
  background: url("/images/circle_orange.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

#fedex_print_label, #fedex_print_label * {
  visibility: hidden;
  display: none;
}

@media print {
  .header,
  .UnfixedHeader,
  .web-header,
  .web-header *,
  .web-header.is-sticky,
  .layout,
  .layout *,
  .footer,
  .footer *,
  .footer-section {
    visibility: hidden;
    display: none;
  }
  #fedex_print_label, #fedex_print_label * {
    visibility: visible;
    display: block;
  }
  #fedex_print_label {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.btn-light{
  /* opacity: 0.8; */
  background-color: #000000;
  color: #ffffff !important;
  border: 1px solid rgba(255, 255, 255, 0.7);
}

@media (min-width: 895px) {
  .prose-wrap.container {
    max-width: 845px !important;
  }
}

@media (min-width: 768px) {
  .prose-wrap h1 {
    font-size: 28px !important;
    line-height: 36px !important;
    letter-spacing: 0.08em !important;
  }
  .wrapper-Bl8Cki3KLP1mbKj74sIej{
    padding-bottom: 60px !important;
  }
  .wrapper-2kJJHhmlO6cLjNC2MuFB0k {
    padding-top: 60px !important;
  }
}
.prose-wrap.container {
  max-width: calc(100% - 48px);
}

.prose-wrap h1 {
  font-size: 20px;
  line-height: 24px;
  text-align: left;
}

.wrapper-Bl8Cki3KLP1mbKj74sIej{
  padding-bottom: 20px;
}
.wrapper-2kJJHhmlO6cLjNC2MuFB0k {
  padding-top: 20px;
}

.prose_depot_event p {
  line-height: 26px;
  font-size: 16px;
}

.prose_affiliate li p {
  font-size: 16px;
  line-height: 26px;
}

.prose_affiliate li p a {
  display: inline-block;
}

.home-splide-arrow button:not(button.wishlist) {
  background-color: white !important;
}

.home-splide-arrow svg:not(.wishlist) path {
  fill: black !important;
}

.content-custom-header {
  font-size: 44px !important;
  line-height: 52px;
}

.content-custom-body * {
  font-size: 20px !important;
  line-height: 28px;
  color: #4C4442;
}

.content-custom-body > ul, .content-custom-body > ul > li {
  list-style: none;
  padding-left: 0;
}

.featured-gear-list > * {
  width: 305px !important;
}

select:required:invalid{
  color: grey;
}
option[value=''][disabled]{
  display: none;
}
option{
  color: #000;
}
.prose.article-content-block :where(a):not(:where([class~="not-prose"] *)) {
  display: inline-grid;
}
.prose.article-content-block .btn-primary, .prose.article-content-block .Btn-primary {
  color: white;
}

.prose.article-content-block .article__show-no-border {
  border-bottom: 0px;
}

.layout-body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.footer-section {
  margin-top: auto;
}

 @media (max-width: 767px) {
   .button-position {
      position: fixed;
      bottom: 0px;
      left: 0px;
      right: 0px;
      width: 100%;
      align-items: center;
      overflow: hidden;
      -webkit-backface-visibility: hidden;
   }
 }

@media (max-width: 767px) {
  .cart-mobile {
  width: 100%;
  max-width: 100%;
  inset: 0;
  height: 100% !important;
  }
  .total-block {
    padding-bottom: 50px;
  }
  .checkout-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding-bottom: 0;
  }
}

.color-option-image {
  height: 32px !important;
  width: 32px !important;
}
.yotpo-bottomline > .yotpo-widget-instance > .yotpo-reviews-star-ratings-widget > .yotpo-widget-clear {
  cursor: pointer;
}

.nav-additional-content-wrap {
  .module-hero {

    height: 100%;

    .module-hero-content {
      height: 100%;
      min-height: 470px !important;

      .hero-bottom-left-block {
        bottom: 10px !important;
        left: 10px !important;
      }
    }
  }
}


.nav-additional-content-wrap-mobile .module-markdown {
  margin: 0 !important;
}

.nav-additional-content-wrap-mobile .module-markdown.container {
  max-width: 100% !important;
}

.nav-l3-wrap ul {
  li.col-2 {
    grid-column: 2;
  }
   li.col-1 {
    grid-column: 1;
  }
}

.default_script_id {
  user-select: none;
  display: none;
  width: 0px;
  height: 0px;
}

.search-view {
  .mobile-filters-selector {
   @apply w-full !important;
  }

  .product-grid {
    @apply mx-0 !important;
  }
}
